import { graphql } from 'gatsby'
import StaticPage from '../containers/Page'

export default StaticPage

export const pageQuery = graphql`
  query StaticPagesQuery(
      $id: String!
  ) {
    page: datoCmsPage(
        originalId: { eq: $id }
    ) {
        slug
        title
        content
    }
  }
`
