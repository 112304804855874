import React from 'react'
import {Layout, Stack, Main} from '@layout'
import Divider from '@components/Divider'

const Post = (props) => {
    const data = props.data.page;
    return (
        <Layout {...props}>
            <Divider space={3} />
            <Stack>
                <Main>
                <h1>{data.title}</h1>
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.content
                    }}
                />
                </Main>
            </Stack>
        </Layout>
    )
}

export default Post
